/* eslint-disable no-extra-boolean-cast */
/* eslint-disable prefer-destructuring */
import {
	Badge,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Tooltip
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FilesActions from "redux-react/actions/filesActions";

const FeedbackElement = ({ fID, name, element }) => {
	const [feedback, setFeedback] = useState(element?.feedback || null);
	const [comment, setComment] = useState(element?.comment || "");

	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);

	const [open, setOpen] = useState(false);

	const saveFeedback = (feed = feedback) => {
		dispatch(
			FilesActions.sendFeedback(
				profile.assistantID,
				fID,
				name,
				{
					feedback: feed,
					comment
				},
				res => {
					setOpen(false);
				}
			)
		);
	};

	const toggleFeedback = value => {
		if (feedback === value) value = "neutral";
		setFeedback(value);
		saveFeedback(value);
	};

	useEffect(() => {
		setFeedback(element?.feedback || null);
		setComment(element?.comment || "");
	}, [fID]);

	return (
		<MDBox display="flex" alignItems="center">
			<IconButton onClick={() => toggleFeedback("positive")}>
				<Icon fontSize="small" color={feedback === "positive" ? "info" : ""}>
					thumb_up_alt
				</Icon>
			</IconButton>
			<IconButton onClick={() => toggleFeedback("negative")}>
				<Icon fontSize="small" color={feedback === "negative" ? "info" : ""}>
					thumb_down_alt
				</Icon>
			</IconButton>
			<Tooltip
				title={
					i18n.exists(`BIO.analyze.${name}`)
						? `Ajouter un commentaire pour ${i18n.t(`BIO.analyze.${name}`)}`
						: `Ajouter un commentaire sur "${name}"`
				}
				placement="top"
			>
				<IconButton onClick={() => setOpen(true)}>
					<Badge color="error" variant="dot" invisible={!Boolean(comment)}>
						<Icon fontSize="small">comment</Icon>
					</Badge>
				</IconButton>
			</Tooltip>
			<Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
				<DialogTitle id="alert-dialog-title">Ajouter un commentaire</DialogTitle>
				<DialogContent>
					<MDBox p={1}>
						<MDInput
							fullWidth
							label="Commentaire ..."
							value={comment}
							multiline
							rows={5}
							onChange={e => {
								setComment(e.target.value);
							}}
						/>
					</MDBox>
				</DialogContent>
				<DialogActions>
					<MDButton variant="contained" onClick={() => setOpen(false)}>
						{i18n.t("SETTINGS.close")}
					</MDButton>
					<MDButton variant="contained" color="info" onClick={() => saveFeedback()}>
						{i18n.t("SETTINGS.save")}
					</MDButton>
				</DialogActions>
			</Dialog>
		</MDBox>
	);
};

export default FeedbackElement;
