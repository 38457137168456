/* eslint-disable no-underscore-dangle */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Badge,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	Tooltip
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";

import FormAction from "redux-react/actions/formAction";

import i18n from "i18n";
import { useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import { t } from "i18next";

const PromptItem = ({ prompt, reloadTemplates, index, count = { count: 0 } }) => {
	const dispatch = useDispatch();

	const [expanded, setExpanded] = useState(false);
	const [openMenu, setOpenMenu] = useState(null);
	const [editMode, setEditMode] = useState(false);

	const [temporaryTemplate, setTemporaryTemplate] = useState({
		name: "",
		description: "",
		prompt: ""
	});

	const saveTemplateChanges = () => {
		const onSuccess = () => {
			dispatch(
				display({
					message: t("PROMPT.PROCESS.EDIT.TEMPLATES.successfullyUpdated"),
					type: "success"
				})
			);
			reloadTemplates();
		};

		const onFailure = () => {
			dispatch(
				display({
					message: t("PROMPT.PROCESS.EDIT.TEMPLATES.errorWhenUpdate"),
					type: "error"
				})
			);
		};

		let data = {
			values: temporaryTemplate,
			target: "promptTemplate",
			unique: {},
			actions: []
		};

		dispatch(FormAction.updateItem(prompt._id, data, onSuccess, onFailure));
	};

	const resetTemporaryTemplate = () => {
		setTemporaryTemplate({
			name: prompt?.name || "",
			description: prompt?.description || "",
			prompt: prompt?.prompt || ""
		});
	};

	const editTemplate = e => {
		e.stopPropagation();
		setExpanded(true);
		setEditMode(true);
		setOpenMenu(null);
	};

	const duplicateTemplate = () => {
		setOpenMenu(null);
		dispatch(
			FormAction.duplicateItem(
				{
					query: { code: prompt.code },
					collection: "promptTemplate",
					updateFields: ["code"]
				},
				res => {
					dispatch(
						display({
							message: i18n.t("FORMS.duplicateSuccess"),
							type: "success"
						})
					);
					reloadTemplates();
				}
			)
		);
	};

	const deleteTemplate = e => {
		e.stopPropagation();

		const onSuccess = () => {
			dispatch(
				display({
					message: t("PROMPT.PROCESS.EDIT.TEMPLATES.successfullyDeleted"),
					type: "success"
				})
			);
			reloadTemplates();
		};

		dispatch(FormAction.deleteItem(prompt._id, "promptTemplate", { delete: true }, onSuccess));

		setOpenMenu(null);
	};

	useEffect(() => {
		setTemporaryTemplate({
			name: prompt?.name || "",
			description: prompt?.description || "",
			prompt: prompt?.prompt || ""
		});
	}, []);

	return (
		<Draggable key={prompt.code} draggableId={prompt.code} index={index}>
			{(provided, snapshot) => (
				<MDBox
					id={prompt._id}
					style={{
						scrollMarginTop: "20vh"
					}}
				>
					<MDBox
						borderRadius="md"
						mb={1}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						isDragging={snapshot.isDragging}
						style={provided.draggableProps.style}
					>
						<Accordion
							expanded={expanded}
							onChange={() => {
								setExpanded(!expanded);
							}}
						>
							<AccordionSummary
								expandIcon={<Icon>expand_more</Icon>}
								aria-controls="panel1-content"
								id="panel1-header"
							>
								<MDBox display="flex" flexDirection="column" flex="1">
									<MDBox display="flex" flex="1">
										<MDBox display="flex" alignItems="center" mr={1}>
											<Tooltip
												placement="top"
												title={`${t("PROMPT.PROCESS.EDIT.TEMPLATES.used")} ${count.count} ${t("PROMPT.PROCESS.EDIT.TEMPLATES.timeInProcess")}`}
											>
												<Badge badgeContent={count.count} color="info">
													<Icon>texture</Icon>
												</Badge>
											</Tooltip>
										</MDBox>
										<MDTypography variant="h6">{prompt.name}</MDTypography>
									</MDBox>
									<MDTypography variant="body2">
										{prompt.description || t("PROMPT.PROCESS.EDIT.TEMPLATES.noDescription")}
									</MDTypography>
								</MDBox>
								<MDBox display="flex" alignItems="center">
									<IconButton
										onClick={e => {
											setOpenMenu(e.currentTarget);
											e.stopPropagation();
										}}
									>
										<Icon>more_vert</Icon>
									</IconButton>
								</MDBox>
								{/* Menu */}
								<Menu
									anchorEl={openMenu}
									open={Boolean(openMenu)}
									onClose={e => {
										setOpenMenu(null);
										e.stopPropagation();
									}}
								>
									<MenuItem onClick={editTemplate}>
										<Icon>edit</Icon>&nbsp;Modifier
									</MenuItem>
									<MenuItem onClick={duplicateTemplate}>
										<Icon>copy</Icon>&nbsp;Dupliquer
									</MenuItem>

									<ConfirmDialogButton
										onConfirm={deleteTemplate}
										onCancel={() => setOpenMenu(null)}
										component={
											<MenuItem>
												<Icon>delete</Icon>&nbsp;Supprimer
											</MenuItem>
										}
										title={`${t("DIALOG.DELETE.delete")} "${prompt.name}"`}
										content={`${t("DIALOG.DELETE.confirmationDelete")} "${prompt.name}" ?`}
									/>
								</Menu>
							</AccordionSummary>
							<AccordionDetails>
								{editMode ? (
									<MDBox>
										<MDInput
											fullWidth
											label={t("PROMPT.PROCESS.EDIT.TEMPLATES.placeholderName")}
											value={temporaryTemplate.name}
											onChange={e => {
												setTemporaryTemplate({
													...temporaryTemplate,
													name: e.target.value
												});
											}}
										/>
										<MDInput
											sx={{ mt: 1 }}
											fullWidth
											label={t("PROMPT.PROCESS.EDIT.TEMPLATES.placeholderDescription")}
											value={temporaryTemplate.description}
											onChange={e => {
												setTemporaryTemplate({
													...temporaryTemplate,
													description: e.target.value
												});
											}}
										/>
										<MDInput
											sx={{ mt: 1 }}
											multiline
											fullWidth
											label={t("PROMPT.PROCESS.EDIT.TEMPLATES.placeholderTemplate")}
											value={temporaryTemplate.prompt}
											onChange={e => {
												setTemporaryTemplate({
													...temporaryTemplate,
													prompt: e.target.value
												});
											}}
											minRows={10}
											maxRows={30}
										/>
										<MDBox display="flex" alignItems="center" justifyContent="end" mt={1}>
											<MDButton
												sx={{ mr: 1 }}
												size="small"
												variant="outlined"
												color="info"
												onClick={e => {
													e.stopPropagation();
													resetTemporaryTemplate();
													setEditMode(false);
												}}
											>
												{i18n.t("SETTINGS.cancel")}
											</MDButton>
											<MDButton
												size="small"
												variant="contained"
												color="info"
												disabled={temporaryTemplate === prompt.prompt}
												onClick={e => {
													e.stopPropagation();
													setEditMode(false);
													saveTemplateChanges();
												}}
											>
												<Icon>save</Icon>&nbsp;{i18n.t("SETTINGS.save")}
											</MDButton>
										</MDBox>
									</MDBox>
								) : (
									<MDTypography
										variant="body2"
										fontSize="medium"
										style={{ whiteSpace: "pre-wrap" }}
									>
										{prompt.prompt}
									</MDTypography>
								)}
							</AccordionDetails>
						</Accordion>
					</MDBox>
					{/* Placeholder when dragging */}
					{snapshot.isDragging && (
						<MDBox
							bgColor="light"
							borderRadius="md"
							mb={1}
							shadow="md"
							p={1}
							display="flex"
							flexDirection="row"
							alignItems="center"
						>
							<MDBox display="flex" flexDirection="column" alignItems="start">
								<MDBox display="flex" alignItems="center">
									<MDBox display="flex" alignItems="center" mr={1}>
										<Icon>texture</Icon>
									</MDBox>
									<MDTypography variant="h6">{prompt.name}</MDTypography>
								</MDBox>
								<MDTypography variant="body2">
									{prompt.description || t("PROMPT.PROCESS.EDIT.TEMPLATES.noDescription")}
								</MDTypography>
							</MDBox>
						</MDBox>
					)}
				</MDBox>
			)}
		</Draggable>
	);
};

/**
 * List all the prompt templates
 * @param {*} param0
 * @returns
 */
const PromptTemplatesList = ({ promptTemplates, reloadTemplates, promptTemplateCount = [] }) => {
	const dispatch = useDispatch();
	const [openDialog, setOpenDialog] = useState(false);
	const [temporaryTemplate, setTemporaryTemplate] = useState({
		name: "",
		description: "",
		code: "",
		prompt: ""
	});
	const [templateSearch, setTemplateSearch] = useState("");

	const closeDialog = () => {
		setOpenDialog(false);
		setTemporaryTemplate({
			name: "",
			description: "",
			code: "",
			prompt: ""
		});
	};

	const onChange = (path, value) => {
		setTemporaryTemplate({
			...temporaryTemplate,
			[path]: value
		});
	};

	const downRef = useRef(null);

	const scrollToBottom = id => {
		setTimeout(() => {
			let element = document.getElementById(id);
			element.scrollIntoView({ behavior: "smooth" });
		}, 500);
	};

	const addNewTemplate = () => {
		setOpenDialog(true);
	};

	const submit = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: t("PROMPT.PROCESS.EDIT.TEMPLATES.successfullyAdded"),
					type: "success"
				})
			);
			reloadTemplates(scrollToBottom(res?.item?._id));
			closeDialog();
		};

		let data = {
			values: temporaryTemplate,
			target: "promptTemplate",
			unique: { code: temporaryTemplate.code },
			actions: []
		};

		dispatch(
			FormAction.addItemEmpty(data, onSuccess, err => {
				dispatch(
					display({
						message: t("PROMPT.PROCESS.EDIT.TEMPLATES.errorWhenAdd"),
						type: "error"
					})
				);
			})
		);
	};

	return (
		<>
			<Dialog
				open={openDialog}
				fullWidth
				maxWidth="md"
				onClose={closeDialog}
				PaperProps={{
					style: {
						height: "100%"
					}
				}}
			>
				<DialogTitle>
					<MDTypography variant="h5">
						{t("PROMPT.PROCESS.EDIT.TEMPLATES.newTemplateTitle")}
					</MDTypography>
				</DialogTitle>
				<DialogContent>
					<MDBox>
						<MDInput
							sx={{ mt: 1 }}
							fullWidth
							label={t("PROMPT.PROCESS.EDIT.TEMPLATES.placeholderName")}
							onChange={e => onChange("name", e.target.value)}
						/>
						<MDInput
							sx={{ mt: 1 }}
							fullWidth
							label={t("PROMPT.PROCESS.EDIT.TEMPLATES.placeholderDescription")}
							onChange={e => onChange("description", e.target.value)}
						/>
						<MDInput
							sx={{ mt: 1 }}
							fullWidth
							label="code"
							onChange={e => onChange("code", e.target.value)}
						/>
						<MDInput
							onChange={e => onChange("prompt", e.target.value)}
							sx={{ mt: 1 }}
							multiline
							fullWidth
							label={t("PROMPT.PROCESS.EDIT.TEMPLATES.placeholderTemplate")}
							minRows={10}
							maxRows={30}
						/>
					</MDBox>
				</DialogContent>
				<DialogActions>
					<MDButton
						color="info"
						variant="outlined"
						onClick={() => {
							closeDialog();
						}}
					>
						{i18n.t("SETTINGS.cancel")}
					</MDButton>
					<MDButton
						color="info"
						variant="contained"
						disabled={
							!temporaryTemplate.name || !temporaryTemplate.code || !temporaryTemplate.prompt
						}
						onClick={submit}
					>
						<Icon>save</Icon>&nbsp;{i18n.t("SETTINGS.save")}
					</MDButton>
				</DialogActions>
			</Dialog>
			<Card sx={{ height: "100%" }}>
				<MDBox
					bgColor="light"
					style={{
						overflowY: "auto",
						height: "100%"
					}}
				>
					<MDBox
						mb={1}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
						// sticky bar
						style={{
							position: "sticky",
							boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
							top: 0,
							zIndex: 5,
							minHeight: "6vh"
						}}
						bgColor="white"
						p={1}
					>
						<MDTypography variant="h5">{t("PROMPT.PROCESS.EDIT.TEMPLATES.templates")}</MDTypography>
						<MDButton size="small" variant="contained" color="info" onClick={addNewTemplate}>
							<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.add")}
						</MDButton>
					</MDBox>
					<MDBox display="flex" flexDirection="column" p={1}>
						<MDBox bgColor="white" mb={1} borderRadius="md" p={1}>
							<MDInput
								fullWidth
								label={t("SETTINGS.search")}
								value={templateSearch}
								onChange={e => {
									setTemplateSearch(e.target.value);
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="clear"
												onClick={() => {
													setTemplateSearch("");
												}}
											>
												<Icon>close</Icon>
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</MDBox>
						<Droppable droppableId="ITEMS" isDropDisabled>
							{(provided, snapshot) => (
								<div ref={provided.innerRef}>
									{promptTemplates
										.filter(
											prompt =>
												prompt.name.toLowerCase().includes(templateSearch.toLowerCase()) ||
												prompt.description.toLowerCase().includes(templateSearch.toLowerCase())
										)
										.map((prompt, index) => (
											<PromptItem
												key={index}
												prompt={prompt}
												index={index}
												reloadTemplates={reloadTemplates}
												count={promptTemplateCount.find(count => count._id === prompt.code)}
											/>
										))}
								</div>
							)}
						</Droppable>
					</MDBox>
					<MDBox ref={downRef}></MDBox>
				</MDBox>
			</Card>
		</>
	);
};

export default PromptTemplatesList;
