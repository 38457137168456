/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-cycle */
/* eslint-disable no-plusplus */

import "./style.css";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import lod_ from "lodash";
import FormActions from "redux-react/actions/formAction";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import i18n from "i18n";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import SettingsActions from "redux-react/actions/settingsActions";

import { Icon } from "@mui/material";
import InputBoolean from "./Inputs/InputBoolean";
import InputDelay from "./Inputs/InputDelay";
import InputPhone from "./Inputs/InputPhone";
import InputDate from "./Inputs/InputDate";
import InputEmail from "./Inputs/InputEmail";
import InputString from "./Inputs/InputString";
import InputNumber from "./Inputs/InputNumber";
import InputCode from "./Inputs/InputCode";
import InputImageList from "./Inputs/InputImageList";
import InputCron from "./Inputs/InputCron";
import InputWebhook from "./Inputs/InputWebhook";
import InputLabel from "./Inputs/InputLabel";
import InputActions from "./Inputs/InputActions";

/**
 * Get the path's label for a dictionary
 * @param {*} dictionary
 * @param {*} path
 * @returns
 */
const getPath = (dictionary, path) => {
	if (!path) {
		return i18n.t("CHANNEL.MAPPING.undefined");
	}

	const parts = path.split(".");
	const result = [];
	let current = "";

	for (let i = 0; i < parts.length; i++) {
		current += parts[i];
		result.push(current);
		current += ".items.";
	}

	let stringPath = "";

	for (let i = 0; i < result.length; i++) {
		stringPath += " " + lod_.get(dictionary, result[i] + ".label.fr") + " /" || "";
	}

	stringPath = stringPath.slice(0, -1);
	return stringPath;
};

/**
 * Component for input
 */
export const FormInput = ({
	item,
	path,
	datas,
	handleChange,
	regexEmail = "",
	listOfApis = [],
	listOfDico = {},
	errorUniqueFields
}) => {
	if (lod_.isEmpty(item)) {
		return null;
	}

	let type = item.type;
	let label = item.label.fr;
	let codes = item.codes || [];
	const [anchorElOutput, setAnchorElOutput] = useState(null);

	let value = lod_.get(datas, path);
	let disabled = !(item?.actions?.edit ?? true);
	let error = errorUniqueFields?.includes(path);

	const onChange = value => {
		handleChange(path, value);
	};

	// Add a star to the label if the field is required
	if (item.isRequired) {
		label = (
			<span>
				{label}
				<span className="mandatoryField">*</span>
			</span>
		);
	}

	switch (type) {
		case "labels":
			return (
				<InputLabel
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "cron":
			return <InputCron label={label} item={item} />;
		case "webhook":
			return <InputWebhook label={label} value={value} onChange={onChange} item={item} />;
		case "imageList":
			return (
				<InputImageList
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "boolean":
			return (
				<InputBoolean
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "phone":
			return (
				<InputPhone
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "date":
		case "datetime":
			return (
				<InputDate
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "email":
			return (
				<InputEmail
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					regexEmail={regexEmail}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "string":
			return (
				<InputString
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "number":
			return (
				<InputNumber
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "delay":
			return (
				<InputDelay
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "code":
			return (
				<InputCode
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					codes={codes}
					path={path}
				/>
			);
		case "actions":
			return (
				<InputActions
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);

		case "dictionaryPath":
			return (
				<MDBox
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="stretch"
					component="fieldset"
					style={{
						borderRadius: "0.375rem 0 0 0.375rem"
					}}
					className="boxInputStyle"
					borderRadius="md"
				>
					<MDBox>
						<legend
							style={{
								lineHeight: 1.5,
								fontSize: "0.875rem",
								color: "#344767",
								fontWeight: 400
							}}
						>
							{label}
						</legend>

						<MDBox>{getPath(listOfDico, value)}</MDBox>
					</MDBox>
					<MDBox
						className="endButtonboxInputStyleCircle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="light"
						onClick={e => setAnchorElOutput(e.target)}
					>
						<Icon fontSize="small">menu</Icon>
					</MDBox>

					<DictionaryMenu
						placement="top"
						dictionary={listOfDico}
						anchorEl={anchorElOutput}
						handleInsertText={e => {
							setAnchorElOutput(null);
							onChange(e);
						}}
						handleClose={() => setAnchorElOutput(null)}
					/>
				</MDBox>
			);
		default:
			return (
				<MDBox mt={1}>
					<MDInput
						disabled={disabled}
						error={error}
						type={type}
						className="dialogInput"
						label={label}
						value={value}
						onChange={e => onChange(e.target.value)}
					/>
				</MDBox>
			);
	}
};
/**
 * Component for create a form from a dictionary
 */
export function LittleForm({
	object,
	metadatasSkeleton,
	handleChange,
	level = 0,
	path = null,
	listAPIs = null,
	errorUniqueFields
}) {
	const [regexEmail, setRegexEmail] = useState("");
	const [listOfApis, setListOfApis] = useState([]);
	const [keyDictionary, setKeyDictionary] = useState({});

	let keys = Object.keys(object);
	const dispatch = useDispatch();
	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);

	useEffect(() => {
		const onSuccess = res => {
			setRegexEmail(res.regex);
		};
		const onSuccessApis = res => {
			setListOfApis(res?.apisList || []);
		};

		if (regexEmail === "") {
			dispatch(FormActions.getRegexLittleForm("email", onSuccess));
		}
		if (lod_.isEmpty(listOfApis)) {
			if (listAPIs) {
				setListOfApis(listAPIs);
			} else {
				dispatch(FormActions.getListApis(assistantIDs, onSuccessApis));
			}
		}
		if (lod_.isEmpty(keyDictionary)) {
			dispatch(
				SettingsActions.getDictionary("context", res =>
					setKeyDictionary({ context: res.dictionary })
				)
			);
		}
	}, []);

	return (
		<div
			style={{
				marginLeft: `${level * 1}rem`
			}}
		>
			{keys.map((key, index) => {
				let item = object[key];
				let display = item.display ?? true;
				if (!display) return null;
				if (item.type === "level") {
					return (
						<div
							key={index}
							style={{
								border: "1px solid #ccc",
								padding: "1rem",
								marginBottom: "1rem",
								borderRadius: "5px"
							}}
						>
							<h3>{item.label.fr}</h3>
							<LittleForm
								object={item.items}
								level={level + 1}
								metadatasSkeleton={metadatasSkeleton}
								handleChange={handleChange}
								path={path ? `${path}.${key}` : key}
								listOfApis={listOfApis}
								errorUniqueFields={errorUniqueFields}
							/>
						</div>
					);
				} else {
					return (
						<MDBox pt={1}>
							<FormInput
								key={index}
								item={item}
								listOfDico={keyDictionary}
								datas={metadatasSkeleton}
								handleChange={handleChange}
								regexEmail={regexEmail}
								path={path ? `${path}.${key}` : key}
								listOfApis={listOfApis}
								errorUniqueFields={errorUniqueFields}
							/>
						</MDBox>
					);
				}
			})}
		</div>
	);
}
