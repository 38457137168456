import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	getItemByID: (_id, collection, route, onSuccess, accessPI = false, account = false) => {
		let data = {
			accessPI,
			collection,
			route,
			account
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getItemEmpty: (target, route, onSuccess) => {
		let data = {
			route
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/form/empty/${target}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	addItemEmpty: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/empty`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	updateItem: (_id, data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/form/update/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	deleteItem: (_id, collection, extraData = {}, onSuccess) => {
		let data = { target: collection, extra: extraData };
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/delete/${_id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error?.message ?? "none"));
					}
				})
			);
		};
	},
	getRegexLittleForm: (typeRegex = "email", onSuccess) => {
		let data = {
			typeRegex
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/litleForm/regex`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getListApis: (assistantID, onSuccess) => {
		let data = {
			assistantID
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/litleForm/listApis`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getProfils: (collection, onSuccess) => {
		let data = {
			collection
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/skill/profils`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	// Get items from collection
	getItemsFromCollection: (collection, data, onSuccess, cluster = {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/collection/${collection}`,
					data: { cluster, ...data },
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error?.message ?? "none"));
					}
				})
			);
		};
	},
	// Duplicate item
	duplicateItem: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/duplicate`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	/* Check if list of unique fields is unique in DB or no */
	/**
	 *
	 * @param {*} data :
	 *  - dictionary : dictionary of the form
	 *  - form : form data
	 *  - collection : collection name
	 */
	validateUniqueFields: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/validateUniqueFields`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	/* Generate a new channel code */
	/**
	 *
	 * @param {*} data :
	 *  - channelType : channel type
	 */
	generateChannelCode: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/generateChannelCode`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	importCSV: (data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/form/import/csv`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error?.message ?? "none"));
					}
				})
			);
		};
	}
};

export default actions;
