import ProfileActions from "redux-react/actions/profileActions";

/**
 * Websocket message handler
 * @param {*} store
 * @param {*} socket
 * @param {*} event
 * @param {*} data
 */
export default function receiveWebsocketMessage({ dispatch, getState }, socket, event, data) {
	switch (event) {
		case "refreshMenu": {
			dispatch(ProfileActions.updateMenu(data.assistantID));
			break;
		}
		default:
			break;
	}
}
