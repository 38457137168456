import lod_ from "lodash";
import useCopyToClipboard from "helpers/useCopyToClipboard";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { t } from "i18next";
import MDBox from "components/Basics/MDBox";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Avatar,
	Card,
	Checkbox,
	Chip,
	ClickAwayListener,
	FormControl,
	FormControlLabel,
	FormGroup,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Popper,
	Select,
	Tooltip,
	Typography
} from "@mui/material";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MDInput from "components/Basics/MDInput";
import { ChromePicker, TwitterPicker } from "react-color";
import { Add, Close, Delete, ExpandMore } from "@mui/icons-material";
import MuiPhoneNumber from "mui-phone-number";
import MDDatePicker from "components/Basics/MDDatePicker";
import MDTypography from "components/Basics/MDTypography";
import { regexMail } from "helpers/utilities";
import BooleanInput from "./BooleanInput";
import DisplayItem from "./DisplayItem";

const InputType = ({
	styleChild,
	codes,
	child,
	key,
	label,
	value,
	onChange,
	customErrors,
	type,
	PI,
	isEdit,
	isEmpty,
	whitelist = false,
	whitelistDynamic = false,
	values,
	routeDict,
	isDisplay,
	valueForm,
	isArray = false,
	regexEmail,
	listAPIs,
	defaultValueForItem,
	isRequired = false,
	checkRequired
}) => {
	const [, handleCopy] = useCopyToClipboard(2000);
	const dispatch = useDispatch();
	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);
	const { user } = useSelector(state => state);
	const [selectedValue, setSelectedValue] = useState(value || []);
	const [viewInvalidEmail, setViewInvalidEmail] = useState("");

	const crmBackEndpoint = process.env.REACT_APP_AMBACK;
	const defaultImage = "https://cdn.pixabay.com/photo/2016/08/31/11/54/user-1633249_960_720.png"; // A DELETE

	const [valueAuto, setValueAuto] = useState(value);
	const [optionAuto, setOptionAuto] = useState([]);

	const [showPassword, setShowPassword] = useState(false);

	const [viewInvalid, setViewInvalid] = useState("");

	const [addCodeCountry, setCodeAddCountry] = useState("");
	const [addValueCodeCountry, setValueCodeAddCountry] = useState("");
	const [addLabelCountry, setLabelAddCountry] = useState("");

	const [stringWarnCode, setStringWarnCode] = useState("");
	const [showWarning, setShowWarning] = useState(false);

	const [showLabelWarning, setShowLabelWarning] = useState(false);
	const [indexWarnCode, setIndexWarnCode] = useState("");

	const [color, setColor] = useState({ hex: value || "#222" });
	const [pickerRef, setPickerRef] = useState(null);

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	let onChangeGate = e => {
		onChange(e);
	};

	let onChangeGateCountry = (childValue, target) => {
		if (childValue === "") {
			setShowLabelWarning(true);
			setIndexWarnCode(target);
			setTimeout(() => {
				setShowLabelWarning(false);
				setIndexWarnCode("");
			}, 3000);
		} else {
			let event = { target: { name: child + "." + target, value: childValue } };
			onChangeGate(event);
		}
	};

	let onChangeGateTags = (list, type, child) => {
		let e = { target: { name: child, value: list } };
		onChange(e, { value: list, type }, child);
	};

	let onChangeGateArray = (list, type, child) => {
		let e = { target: { name: child, value: list } };
		onChange(e, { value: list, type, isArray: true }, child);
	};

	if (PI && !isEdit && type !== "chip") {
		PI = false;
	}

	async function loadSuggestions(searchText, searchFields, apiName, action, limit = 10) {
		let attribute = whitelistDynamic?.params?.attribute;

		let APIFind;
		let actionFind;
		if (apiName && action && !lod_.isEmpty(listAPIs)) {
			APIFind = listAPIs.find(item => item.name === apiName) || {};
			actionFind = APIFind.config.action[action];
		} else {
			return [];
		}

		let account = actionFind?.account ?? false;
		// Merge collections is an array of collections name, that will be requested in the same time
		// The result will be merged in the same array
		const mergeCollections = actionFind?.mergeCollections || [];

		let config = {
			method: actionFind?.method,
			maxBodyLength: Infinity,
			url: `${APIFind.config.host}/api/v1/ressource/${actionFind?.collection}/dynamic?search=${searchText}&page=1&limit=${limit}&account=${account}`,
			data: { filter: actionFind?.filter, searchFields, attribute, mergeCollections },
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: assistantIDs,
				"Content-Type": "application/json"
			}
		};

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});
		return result?.results || [];
	}

	const copyValue = (valueCopy, byPass = true) => {
		if (!PI && byPass) {
			handleCopy(valueCopy);
			dispatch(
				display({
					message: t("FORMS.copyToClipboard"),
					type: "success"
				})
			);
		}

		// setSnackbarStatus({ open: true, message: `identifiant copié ` });
	};

	const getOptionLabel = option => {
		let labelAttribute = option[whitelistDynamic?.params?.attribute] || "";
		let label = whitelistDynamic.params?.searchFields
			? whitelistDynamic.params?.searchFields.map(key => option[key]).join(" - ")
			: "";
		if (
			whitelistDynamic.params?.searchFields &&
			!lod_.isEmpty(whitelistDynamic.params?.searchFields)
		) {
			label = labelAttribute + " - " + label;
		} else {
			label = labelAttribute;
		}

		return label;
	};

	const onChangeCRM = (event, newValue, option) => {
		if (option === "selectOption") {
			setValueAuto(newValue[whitelistDynamic.params.attribute]);
			event = { target: { name: child, value: newValue[whitelistDynamic.params.attribute] } };
			onChangeGate(event);
		} else if (option === "clear") {
			setValueAuto("");
			event = { target: { name: child, value: null } };
			onChangeGate(event);
		}
	};

	const onChangeCountry = (event, newValue, option) => {
		if (option === "selectOption") {
			setCodeAddCountry(newValue);
			setValueCodeAddCountry(newValue);
		} else if (option === "clear") {
			setCodeAddCountry("");
			setValueCodeAddCountry("");
		}
	};

	const ValueChange = async valueC => {
		setValueAuto(valueC);

		let result = await loadSuggestions(
			valueC,
			whitelistDynamic.params.searchFields || [],
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptionAuto(result);
	};

	const ValueChangeCountry = async valueC => {
		setCodeAddCountry(valueC);
		let result = await loadSuggestions(
			valueC,
			whitelistDynamic.params.searchFields || [],
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptionAuto(result);
	};

	const handleImageChange = e => {
		e.preventDefault();
		let reader = new FileReader();
		let newFile = e.target.files[0];
		reader.onloadend = () => {
			onChangeGate(e);
		};
		if (newFile) {
			reader.readAsDataURL(newFile);
		}
	};

	const displayPicker = e => {
		setPickerRef(e.target);
	};

	const handleColorSelection = color => {
		if (PI) {
			setColor(color);
			let event = { target: { name: child, value: color.hex } };
			onChangeGate(event);
		}
	};

	const deleteLabel = target => {
		let copyValue = lod_.cloneDeep(value);

		delete copyValue[target];

		let event = { target: { name: child, value: copyValue } };

		onChangeGate(event);
	};

	const addLabel = () => {
		if (addLabelCountry !== "" && addValueCodeCountry !== "") {
			if (lod_.get(value, addValueCodeCountry)) {
				setShowWarning(true);
				setStringWarnCode(t("FORMS.uniqueCodeKey"));
				setTimeout(() => {
					setShowWarning(false);
				}, 3000);
			} else {
				let event = { target: { name: child + "." + addValueCodeCountry, value: addLabelCountry } };
				onChangeGate(event);
				setCodeAddCountry("");
				setLabelAddCountry("");
				setValueCodeAddCountry("");
			}
		} else {
			setShowWarning(true);
			setStringWarnCode(t("FORMS.labelOrCodeRequired"));
			setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}
	};

	const clearWD = () => {
		ValueChange("");
		let event = { target: { name: child, value: "" } };
		onChangeGate(event);
	};

	/**
	 * Set by default options for WD
	 */
	useEffect(() => {
		if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
			ValueChange(value || "");
		}
		if (isRequired && !["boolean"].includes(type)) {
			checkRequired(value, child);
		}
	}, []);

	/**
	 * Field WhiteList return if normal field or if have whitelist
	 * @returns Field
	 */
	const whiteListItem = () => {
		if (whitelist && !lod_.isEmpty(whitelist) && !isArray) {
			return (
				<MDBox mb={2}>
					<FormControl fullWidth onClick={() => copyValue(value)}>
						<InputLabel id="select-label">{label}</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={label}
							value={value || ""}
							onChange={onChangeGate}
							name={child}
							disabled={!PI}
						>
							{whitelist.map((option, index) => (
								<MenuItem key={index} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</MDBox>
			);
		} else if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
			return (
				<MDBox mb={2} onClick={() => copyValue(value)} style={{ width: "100%", display: "flex" }}>
					<MDBox style={{ width: "100%" }}>
						<Autocomplete
							id="auto"
							freeSolo
							disableClearable
							name={child}
							key={key}
							disabled={!PI}
							options={optionAuto}
							onChange={onChangeCRM}
							getOptionLabel={getOptionLabel}
							inputValue={valueAuto || ""}
							renderInput={params => (
								<MDInput {...params} label={label} onChange={e => ValueChange(e.target.value)} />
							)}
						/>
					</MDBox>
					{value && value !== "" && (
						<IconButton disabled={!PI} onClick={() => clearWD()}>
							<Close />
						</IconButton>
					)}
				</MDBox>
			);
		} else {
			let list = whitelist || [];
			return (
				<MDBox sx={{ mb: 1 }} onClick={() => copyValue(value)}>
					{isArray ? (
						<Tooltip
							open={viewInvalid === child}
							title={t("FORMS.whitelistInvalidList")}
							placement="bottom"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={lod_.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									if (lod_.isEmpty(list)) {
										onChangeGateTags(newValue, type, child);
									} else {
										const stringValuesWhite = newValue.filter(val => list.includes(val));
										if (stringValuesWhite.length === newValue.length) {
											onChangeGateTags(stringValuesWhite, type, child);
										} else {
											setViewInvalid(child);
											setTimeout(() => {
												setViewInvalid("");
											}, 3000);
										}
									}
								}}
								readOnly={!PI}
								multiple
								id="tags-filled"
								options={list}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip label={option} {...getTagProps({ index })} key={index} />;
									});
								}}
								renderInput={params => <MDInput {...params} label={label} />}
							/>
						</Tooltip>
					) : (
						<MDInput
							name={child}
							type={type}
							className="dialogInput"
							label={label}
							key={key}
							value={value || ""}
							disabled={!PI}
							onChange={onChangeGate}
						/>
					)}
				</MDBox>
			);
		}
	};

	// Special handle for defaultValue of dicitonary
	if (child === "defaultValue" && routeDict === "dictionary") {
		let defaultType = lod_.get(values, "type");
		if (!["code", "level", "labels", "color", "codeImage"].includes(defaultType)) {
			type = defaultType;
			onChangeGate = e => {
				onChange({
					event: e,
					defaultType
				});
			};
		} else {
			type = "none";
		}
	}

	if (child === "whitelist" && routeDict === "dictionary") {
		let defaultType = lod_.get(values, "type");
		if (
			!defaultType ||
			["boolean", "code", "level", "labels", "color", "codeImage", "textarea"].includes(defaultType)
		) {
			type = "none";
		} else {
			type = defaultType;
		}
	}

	if (child === "isArray" && routeDict === "dictionary") {
		let defaultType = lod_.get(values, "type");
		if (
			!defaultType ||
			["labels", "color", "tagList", "boolean", "code", "codeImage", "textarea"].includes(
				defaultType
			)
		) {
			type = "none";
		}
	}

	if (child === "isDynamic" && routeDict === "dictionary") {
		let defaultType = lod_.get(values, "type");
		if (
			!defaultType ||
			["boolean", "code", "level", "color", "codeImage", "textarea"].includes(defaultType)
		) {
			type = "none";
		}

		if (["labels"].includes(defaultType)) {
			PI = false;
			value = true;
		}
	}

	if (child === "code" && routeDict === "labels") {
		if (values?.catalog === true) {
			PI = false;
		}
	}

	if (isDisplay === false) {
		type = "none";
	}
	switch (type) {
		case "boolean":
			if (lod_.isNil(value)) {
				if (!lod_.isNil(defaultValueForItem)) {
					value = defaultValueForItem;
					let event = { target: { name: child, value: defaultValueForItem } };
					onChangeGate(event);
				} else {
					value = false;
					let event2 = { target: { name: child, value: false } };

					onChangeGate(event2);
				}
			}
			return (
				<MDBox sx={{ mb: 1 }}>
					<BooleanInput value={value} child={child} PI={PI} label={label} onChange={onChangeGate} />
				</MDBox>
			);
		case "phone":
			if (whitelist) {
				return (
					<MDBox mb={2}>
						<FormControl fullWidth onClick={() => copyValue(value)}>
							<InputLabel id="select-label">{label}</InputLabel>
							<Select
								labelId="select-label"
								id="select"
								label={label}
								value={value || ""}
								onChange={onChangeGate}
								name={child}
								disabled={!PI}
							>
								{whitelist.map((option, index) => (
									<MenuItem key={index} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</MDBox>
				);
			} else {
				return (
					<>
						<MDBox mb={2} onClick={() => copyValue(value)}>
							<MuiPhoneNumber
								defaultCountry="fr"
								onChange={onChangeGate}
								name={child}
								type="tel"
								className="dialogInput"
								label={label}
								key={key}
								value={value || null}
								disabled={!PI}
								variant="outlined"
							/>
						</MDBox>
						<p style={{ color: "red" }}>{customErrors}</p>
					</>
				);
			}
		case "date": {
			const handleCopyDate = e => {
				if (!PI) {
					let dateParse = new Date(e);
					copyValue(dateParse.toLocaleDateString());
				}
			};

			return (
				<>
					<MDBox mb={2} onClick={() => handleCopyDate(value)}>
						<MDDatePicker
							name={child}
							type="date"
							className="dialogInput"
							label={label}
							key={key}
							value={value || null}
							disabled={!PI}
							onChange={onChangeGate}
							options={{
								time_24hr: true,
								dateFormat: "d M Y"
							}}
							input={{
								className: "fullWidthDatePicker date_input_light",
								placeholder: `${label} `,
								disabled: !PI,
								style: { width: "100%" }
							}}
						/>
					</MDBox>

					<p style={{ color: "red" }}>{customErrors}</p>
				</>
			);
		}
		case "number": {
			let list = whitelist || [];

			if (isArray) {
				return (
					<MDBox mb={1}>
						<Tooltip
							open={viewInvalid === child}
							title={t("FORMS.whitelistInvalidList")}
							placement="bottom"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={lod_.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									if (lod_.isEmpty(list)) {
										const numericValues = newValue.map(val => parseFloat(val));
										onChangeGateArray(numericValues, type, child);
									} else {
										const numericValues = newValue.map(val => parseFloat(val));
										const numericValuesWhite = numericValues.filter(val => list.includes(val));
										if (numericValuesWhite.length === newValue.length) {
											onChangeGateArray(numericValuesWhite, type, child);
										} else {
											setViewInvalid(child);
											setTimeout(() => {
												setViewInvalid("");
											}, 3000);
										}
									}
								}}
								readOnly={!PI}
								multiple
								id="tags-filled"
								options={list}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip label={option} {...getTagProps({ index })} key={index} />;
									});
								}}
								renderInput={params => <MDInput {...params} type="number" label={label} />}
							/>
						</Tooltip>
					</MDBox>
				);
			} else if (lod_.isArray(whitelist) && !lod_.isEmpty(whitelist)) {
				return <MDBox>{whiteListItem()}</MDBox>;
			} else {
				return (
					<MDBox>
						<MDBox sx={{ mb: 1 }} onClick={() => copyValue(value)}>
							<MDInput
								name={child}
								type="number"
								className="dialogInput"
								label={label}
								key={key}
								value={value || value === 0 ? value : null}
								disabled={!PI}
								onChange={onChangeGate}
							/>
						</MDBox>
						<p style={{ color: "red" }}>{customErrors}</p>
					</MDBox>
				);
			}
		}
		case "code":
			if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
				return (
					<>
						{whiteListItem()}
						<p style={{ color: "red" }}>{customErrors}</p>
					</>
				);
			} else {
				return (
					<FormControl fullWidth onClick={() => copyValue(value)} sx={{ mb: 1 }}>
						<InputLabel id="select-label">{label}</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={label}
							value={value || ""}
							onChange={onChangeGate}
							name={child}
							disabled={!PI}
						>
							{codes
								.sort((a, b) => a.label.localeCompare(b.label))
								.map((code, index) => (
									<MenuItem key={index} value={code.value}>
										<MDBox display="flex" flexDirection="row" alignItems="center">
											{code.icon && (
												<MDBox sx={{ mr: 1 }}>
													<Icon fontSize="medium">{code.icon}</Icon>
												</MDBox>
											)}
											<MDBox>
												<MDTypography variant="h6" fontSize="small">
													{code.label}
												</MDTypography>
												<MDTypography variant="body2" fontSize="small">
													{code.description}
												</MDTypography>
											</MDBox>
										</MDBox>
									</MenuItem>
								))}
						</Select>
						<p style={{ color: "red" }}>{customErrors}</p>
					</FormControl>
				);
			}
		case "email": {
			let listEmail = whitelist || [];

			if (isArray) {
				return (
					<MDBox mb={1}>
						<Tooltip
							open={child === viewInvalid}
							title={viewInvalidEmail}
							placement="right"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={lod_.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									const isValid = newValue.every(val => val === "" || regexMail(val, regexEmail));

									if (isValid) {
										if (lod_.isEmpty(listEmail)) {
											onChangeGateArray(newValue, type, child);
										} else {
											const stringValuesWhite = newValue.filter(val => listEmail.includes(val));
											if (stringValuesWhite.length === newValue.length) {
												onChangeGateArray(stringValuesWhite, type, child);
											} else {
												setViewInvalid(child);
												setViewInvalidEmail(t("FORMS.whitelistInvalidList"));

												setTimeout(() => {
													setViewInvalid("");
													setViewInvalidEmail("");
												}, 3000);
											}
										}
									} else {
										setViewInvalid(child);
										setViewInvalidEmail(t("FORMS.emailInvalidList"));

										setTimeout(() => {
											setViewInvalidEmail("");

											setViewInvalid("");
										}, 3000);
									}
								}}
								readOnly={!PI}
								multiple
								id="tags-filled"
								options={listEmail}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip label={option} {...getTagProps({ index })} key={index} />;
									});
								}}
								renderInput={params => <MDInput {...params} label={label} />}
							/>
						</Tooltip>
					</MDBox>
				);
			} else if (lod_.isArray(whitelist) && !lod_.isEmpty(whitelist)) {
				return <MDBox>{whiteListItem()}</MDBox>;
			} else {
				return (
					<MDBox mb={2} onClick={() => copyValue(value)}>
						<MDInput
							name={child}
							type={type}
							className="dialogInput"
							label={label}
							key={key}
							value={value || ""}
							disabled={!PI}
							onChange={onChange}
						/>
					</MDBox>
				);
			}
		}
		case "codeImage":
			return (
				<MDBox sx={{ mb: 1 }} onClick={() => copyValue(value)}>
					<FormControl style={{ width: "12%" }}>
						<InputLabel id="select-label">{label}</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={label}
							value={value || ""}
							onChange={onChange}
							name={child}
							disabled={!PI}
						>
							{codes &&
								!lod_.isEmpty(codes) &&
								codes.map((code, index) => (
									<MenuItem key={index} value={code.value}>
										<img key={index} src={code.label} alt="Filter_flag" width="20" height="20" />
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</MDBox>
			);
		case "mdp":
			return (
				<>
					<MDBox mb={2} onClick={() => copyValue(value)}>
						{isEmpty && (
							<MDBox className="password-input-wrapper">
								<MDInput
									name={child}
									type={showPassword ? "text" : "password"}
									className="dialogInput"
									style={{ width: "94%" }}
									label={label}
									key={key}
									value={value || null}
									disabled={!PI}
									onChange={onChange}
								/>
								<Tooltip
									title={showPassword ? t("FORMS.hidePassword") : t("FORMS.showPassword")}
									placement="right"
								>
									<IconButton
										className="show-password-button"
										onClick={handleTogglePasswordVisibility}
									>
										{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
									</IconButton>
								</Tooltip>
							</MDBox>
						)}
					</MDBox>
					<Card style={{ width: "94%" }}>{customErrors}</Card>
				</>
			);
		case "picture": {
			let imagePreview = true;

			let imageSource;
			if (value === "" || lod_.isNil(value)) {
				imagePreview = false;
				imageSource = defaultImage;
			} else {
				imageSource = value;
			}

			return (
				<MDBox mb={2} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Typography>{label}</Typography>

					{imagePreview ? (
						<MDBox>
							{PI && (
								<IconButton
									className="picture-delete"
									aria-label="delete"
									size="small"
									onClick={onChangeGate}
								>
									<ClearIcon />
								</IconButton>
							)}
							<Avatar
								style={{ width: 100, height: 100, objectFit: "cover" }}
								alt="Profil"
								src={imageSource}
							/>
						</MDBox>
					) : (
						<MDBox sx={{ width: 100 }} mb={2}>
							<label htmlFor="image-input" style={{}}>
								<Avatar
									style={{ width: 100, height: 100, cursor: "pointer", objectFit: "cover" }}
									alt="default"
									src={imageSource}
								/>

								<input
									id="image-input"
									disabled={!PI}
									style={{ display: "none" }}
									type="file"
									accept="image/*"
									onChange={handleImageChange}
								/>
							</label>
						</MDBox>
					)}
					<p style={{ color: "red" }}>{customErrors}</p>
				</MDBox>
			);
		}
		case "tagList": {
			let listTag = [];
			return (
				<Autocomplete
					value={lod_.isArray(value) ? value : []}
					onChange={(event, newValue) => {
						onChangeGateTags(newValue, type, child);
					}}
					readOnly={!PI}
					multiple
					id="tags-filled"
					options={listTag}
					freeSolo
					renderTags={(tags, getTagProps) => {
						return tags.map((option, index) => {
							return <Chip label={option} {...getTagProps({ index })} key={index} />;
						});
					}}
					renderInput={params => <MDInput {...params} label={label} />}
				/>
			);
		}
		case "check":
			return (
				<MDBox>
					{routeDict === "dictionary" ? (
						<MDBox>
							{values?.type ? (
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												disabled={!PI}
												checked={value}
												onChange={e =>
													onChange(
														{ target: { name: child, value: e.target.checked } },
														{ value: e.target.checked, type },
														child
													)
												}
											/>
										}
										label={label}
									/>
								</FormGroup>
							) : (
								<MDBox></MDBox>
							)}
						</MDBox>
					) : (
						<FormGroup>
							<FormControlLabel control={<Checkbox checked={value} />} label={label} />
						</FormGroup>
					)}
				</MDBox>
			);
		case "none":
			return null;
		case "labels":
			return (
				<Accordion className="customAccordion" key={key}>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<span style={{ fontWeight: "bolder" }}>{label}</span>
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ display: "flex", width: "100%", flexDirection: "column" }}>
						<MDBox>
							{values?.catalog !== true && (
								<>
									<MDTypography variant="h6">{t("FORMS.addCode")}</MDTypography>
									<MDBox style={{ display: "flex", width: "100%", alignItems: "center" }}>
										<Autocomplete
											id="auto"
											style={{ width: "20%", marginRight: "1%" }}
											disablePortal
											name={child}
											key={key}
											disabled={!PI}
											options={optionAuto}
											onChange={onChangeCountry}
											getOptionLabel={getOptionLabel}
											inputValue={addCodeCountry}
											renderOption={(suggestedValue, option) =>
												DisplayItem(suggestedValue, option, onChangeCountry)
											}
											renderInput={params => (
												<MDInput
													{...params}
													label={label}
													onChange={e => ValueChangeCountry(e.target.value)}
												/>
											)}
										/>
										<MDInput
											name={child}
											label={label}
											key={key}
											value={addLabelCountry}
											disabled={!PI}
											onChange={e => setLabelAddCountry(e.target.value)}
										/>
										<Tooltip
											open={showWarning}
											title={stringWarnCode}
											placement="right"
											disableFocusListener
											disableHoverListener
											disableTouchListener
										>
											<IconButton disabled={!PI} size="small" onClick={() => addLabel()}>
												<Add />
											</IconButton>
										</Tooltip>
									</MDBox>
									<hr
										style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "10px 0px" }}
									/>
								</>
							)}
							<Typography>
								<MDTypography variant="h6">{t("FORMS.listCodes")}</MDTypography>
							</Typography>

							{!lod_.isEmpty(value) ? (
								<>
									{Object.keys(value).map((childItem, key) => {
										return (
											<MDBox style={{ display: "flex", alignItems: "center" }} key={key}>
												<Autocomplete
													id="auto"
													style={{ width: "20%", marginRight: "1%" }}
													freeSolo
													name={childItem}
													key={key}
													disabled
													options={optionAuto}
													onChange={onChangeCRM}
													getOptionLabel={getOptionLabel}
													inputValue={childItem}
													renderInput={params => <MDInput {...params} label={label} />}
												/>
												<MDInput
													name={child + "." + childItem}
													label={label}
													key={key}
													value={value[childItem]}
													disabled={!PI}
													onChange={e => onChangeGateCountry(e.target.value, childItem)}
												/>
												<Tooltip
													open={showLabelWarning && childItem === indexWarnCode}
													title={t("FORMS.labelRequired")}
													placement="right"
													disableFocusListener
													disableHoverListener
													disableTouchListener
												>
													{values?.catalog !== true && (
														<IconButton
															disabled={!PI}
															size="small"
															onClick={() => deleteLabel(childItem)}
														>
															<Delete />
														</IconButton>
													)}
												</Tooltip>
											</MDBox>
										);
									})}
								</>
							) : (
								<Typography>
									<MDTypography variant="h6">{t("FORMS.emptyCodes")}</MDTypography>
								</Typography>
							)}
						</MDBox>
					</AccordionDetails>
				</Accordion>
			);
		case "color":
			return (
				<>
					<MDBox mb={1} className="colorContainer">
						<MDBox className="colorPreset">
							<TwitterPicker
								width="100%"
								triangle="hide"
								color={color}
								onChange={handleColorSelection}
							/>
						</MDBox>
						<MDBox
							className="buttonColorPicker"
							style={{
								backgroundColor: color.hex
							}}
							onClick={displayPicker}
						>
							<Icon fontSize="medium" className="buttonColorPickerIcon">
								colorize
							</Icon>
						</MDBox>
					</MDBox>
					<Popper
						id={pickerRef ? "simple-popper" : ""}
						open={Boolean(pickerRef) && PI}
						anchorEl={pickerRef}
						placement="right"
						style={{
							zIndex: 10000
						}}
					>
						{() => (
							<ClickAwayListener
								onClickAway={e => {
									setPickerRef(null);
								}}
							>
								<Paper>
									<ChromePicker disableAlpha color={color} onChange={handleColorSelection} />
								</Paper>
							</ClickAwayListener>
						)}
					</Popper>
				</>
			);
		case "textarea":
			return (
				<MDBox sx={{ mb: 1 }}>
					<MDInput
						name={child}
						type={type}
						className="dialogInput"
						label={label}
						key={key}
						value={value || ""}
						disabled={!PI}
						onChange={onChangeGate}
						multiline
						rows={4}
					/>
					<p style={{ color: "red" }}>{customErrors}</p>
				</MDBox>
			);
		default:
			return (
				<>
					{whiteListItem()}
					<p style={{ color: "red" }}>{customErrors}</p>
				</>
			);
	}
};

export default InputType;
